<script>
    import { required, minLength, sameAs } from "vuelidate/lib/validators";
    import Auth from '@/services/Auth';
    import validationMessages from '@/components/validations'


    export default {
        components:{
            validationMessages
        },
        props: ['user_id'],
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                password: '',
                password_confirmation: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                showLoader: false
            };
        },
        validations: {
            password: {
                required,
                minLength: minLength(6),
                valid: function(value) {
                    const containsUppercase = /[A-Z]/.test(value)
                    const containsLowercase = /[a-z]/.test(value)
                    const containsNumber = /[0-9]/.test(value)
                    //const containsSpecial = /[#?!@$%^&*-]/.test(value)
                    return containsUppercase && containsLowercase && containsNumber
                }
            },
            password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            },
        },
        methods: {

            async ChangePassword(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {
                        await Auth.changePassword({
                            user_id: this.user_id,
                            password: this.password,
                            password_confirmation: this.password_confirmation,
                            csrf_token: this.csrf_token
                        }).then((response) => {
                            const res   = response.data.data  ? response.data.data  : false;
                            const error = response.data.error ? response.data.error : '';
                            if(res && error==''){
                                this.successmsg("User password changed successfully");
                                this.closeModal();
                            }else{
                                this.failedmsg("Failed to change user password")
                            }

                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("User not saved")
                    }
                }
                this.tryingToEdit = false;
            },

            closeModal() {
                this.showModal = false;
                this.password = ''
                this.password_confirmation = ''
                this.tryingToEdit = false
                this.submitted = false
            },

            initialData(){

            }
        }
    };
</script>

<template>
    <b-modal @shown="initialData" v-model="showModal" id="change_password" title="Change User Password" title-class="font-18" size="lg" centered>
        <form @submit.prevent="ChangePassword" v-if="!showLoader">
            <div class="row">
                <div class="col">
                    <b-form-group label="Password" label-for="formrow-password-input" class="mb-3">
                        <b-form-input
                                v-model.trim="password"
                                type="password"
                                aria-describedby="password-feedback"
                                autocomplete="new-password"
                                aria-autocomplete="none"
                                :class="{
                                  'is-invalid': submitted && $v.password.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Password'" :validationName="$v.password"></validationMessages>
                    </b-form-group>

                    <b-form-group label="Confirm Password" label-for="formrow-confirm_password-input" class="mb-3">
                        <b-form-input
                                v-model.trim="password_confirmation"
                                type="password"
                                aria-describedby="password-feedback"
                                autocomplete="new-password"
                                aria-autocomplete="none"
                                :class="{
                                  'is-invalid': submitted && $v.password_confirmation.$error,
                                }"
                        >
                        </b-form-input>
                        <validationMessages v-if="submitted" :fieldName="'Confirm Password'" :validationName="$v.password_confirmation"></validationMessages>
                    </b-form-group>


                </div>
            </div>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="ChangePassword" :disabled="tryingToEdit || showLoader">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>