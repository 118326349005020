<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import ColumnVisibility from '@/components/ColumnVisibility';
    import Auth from '@/services/Auth';
    import modalAddUser from  '@/components/modals/auth/modalAddUser';
    import modalEditUser from  '@/components/modals/auth/modalEditUser';
    import modalChangePassword from  '@/components/modals/auth/modalChangePassword';
    import Swal from "sweetalert2";

    export default {
        components: { Layout, PageHeader, ColumnVisibility, modalAddUser, modalEditUser, modalChangePassword },
        page: {
            title: "Users",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                error: null,
                title: "Users",
                items: [
                    {
                        text:"Users",
                        active: true,
                        href: "/users"
                    }
                ],
                usersData: [],
                isBusy: false,
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "creation_date",
                sortDesc: true,
                showLoader:false,
                user_id: '',
                fields: [
                    {
                        key: "username",
                        label: "Username",
                        visible: true
                    },
                    {
                        key: "role_name",
                        label: "Role",
                        visible: true
                    },
                    {
                        key: "description",
                        label: "Description",
                        visible: true
                    },
                    {
                        key: "action",
                        label: " ",
                        sortable: false,
                        visible: true
                    },
                ],
            };
        },
        async created() {
            this.getUsers();
        },
        methods: {

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            async getUsers(){
                try {
                    this.showLoader = true
                    var filters = this.getFilters()
                    await Auth.getUsers(filters).then((response) => {
                        this.usersData = response.data.users;
                        this.totalRows = this.usersData.length
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Can not get users")
                    }).finally(() => {
                        this.showLoader = false
                    })

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg("Can not get users")
                }
            },

            getFilters(){
                var filter_string = '';

                return filter_string = filter_string.substring(1);
            },

            onDropdownHide(bvEvent){
                bvEvent.preventDefault();
            },

            callModalAddUser(){
                this.$bvModal.show("add_user");
            },

            editUser(id){
                this.$bvModal.show("edit_user");
                this.user_id = id
            },
            changePassword(id){
                this.$bvModal.show("change_password");
                this.user_id = id
            },
            deleteUser(id){

                Swal.fire({
                    title: "Remove this user?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            Auth.deleteUser(id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getUsers()
                                        Swal.fire("Remove user", "User is successfully removed!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })

                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-md-12 ">
                                <div class="mb-3">
                                    <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                                       @click="callModalAddUser">
                                        <i class="mdi mdi-plus me-2"></i>
                                        Create New
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12" v-if="!showLoader">
                <div class="card">
                    <div class="card-body">

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length d-inline-block">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>

                                <ColumnVisibility :fields="fields" :visFields="visibleFields"></ColumnVisibility>
                            </div>

                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive-sm mb-0">
                            <b-table
                                    :items="usersData"
                                    :busy="isBusy"
                                    :fields="visibleFields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                                    show-empty
                                    empty-text="No Data Found"
                            >

                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" title="Edit" @click="editUser(data.item.id)">
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" title="Change Password" @click="changePassword(data.item.id)">
                                                <i class="uil uil-padlock font-size-18"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-danger" title="Delete" @click="deleteUser(data.item.id)">
                                                <i class="uil uil-trash-alt font-size-18"></i>
                                            </a>
                                        </li>

                                    </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body d-flex align-items-center justify-content-center" v-else>
                <b-spinner large></b-spinner>
            </div>
        </div>

        <!-- MODALS -->
        <modalAddUser @onRefresh="getUsers()"></modalAddUser>
        <modalEditUser :user_id="user_id" @onRefresh="getUsers()"></modalEditUser>
        <modalChangePassword :user_id="user_id"></modalChangePassword>
        <!-- /MODALS -->

    </Layout>
</template>
